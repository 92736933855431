import React from 'react';
import Button from 'react-bootstrap/Button';
import './videoCard.css';

const VideoCard = ({ link, title, image, description, handleShowCallback }) => {
  return (
    <div
      onClick={handleShowCallback}
      onKeyPress={handleShowCallback}
      role="button"
      tabIndex="0"
      className="videoCard h-100 card "
    >
      <img alt={title} src={image} />
      <div className="play-button"></div>
      <div className="card-body">
        <h5 className="card-title videoCard-title">{title}</h5>
        <p>{description}</p>
        <Button variant="primary" className="watch-button">
          WATCH
        </Button>
      </div>
    </div>
  );
};
export default VideoCard;
