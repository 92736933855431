import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Gallery from "react-photo-gallery";
import Spinner from "react-bootstrap/Spinner";
import VideoCard from "../components/video-card/videoCard";
import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
import "./styles/media.css";

const Media = (props) => {
  const [videos, setVideos] = useState([]);
  const [canLoadVideo, setCanLoadVideo] = useState(true);
  const [photos, setPhotos] = useState([]);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const handleClose = () => setShowVideoModal(false);
  const photosData = props.data.allContentfulGallery.nodes[0].images;

  useEffect(() => {
    reworkPhotosArray();
    axios
      .get(
        "https://www.googleapis.com/youtube/v3/search?key=AIzaSyDPEcOVzWWGDG4IX9qJ9GugERWEfbPiBWI&channelId=UCGIEX_XejJPLo7XQL-80Uvg&part=snippet,id&order=date&maxResults=200"
      )
      .then((response) => {
        response.data.items ? setVideos(response.data.items) : setVideos([]);
      })
      .catch((error) => {
        setCanLoadVideo(false);
      });
  }, []); // eslint-disable-line
  const reworkPhotosArray = () => {
    let photosArray = [];
    photosData.forEach((photo, index) => {
      photosArray[index] = {
        src: photo.fixed.src,
        width: photo.fixed.width,
        height: photo.fixed.height,
        alt: photo.description,
      };
    });
    setPhotos(photosArray);
  };

  const linksOfPhotos = (photos) => {
    let photosArray = [];
    photos.forEach((photo, index) => {
      photosArray[index] = {
        photo: photo.src,
        caption: photo.alt,
      };
    });
    return photosArray;
  };

  const handleShow = (video) => {
    setSelectedVideo({
      link: `https://youtube.com/embed/${video.id.videoId}?&autoplay=1`,
      title: video.snippet.title,
    });
    setShowVideoModal(true);
  };

  const handleGalleryClick = (event, index) => {
    setSelectedPhoto(index.index);
    setShowImageModal(true);
  };

  return (
    <Layout location={props.location}>
      <Helmet>
        <title>Media - {props.data.site.siteMetadata.title}</title>
        <meta
          name="description"
          content={props.data.site.siteMetadata.description}
        />
      </Helmet>
      <h2 className="section-headline">Photos</h2>
      {photos.length < 1 ? <Spinner animation="border"></Spinner> : ""}
      <Gallery
        targetRowHeight={180}
        columns={4}
        photos={photos}
        onClick={(event, index) => {
          handleGalleryClick(event, index);
        }}
      />
      <ReactBnbGallery
        show={showImageModal}
        photos={linksOfPhotos(photos)}
        onClose={() => setShowImageModal(false)}
        activePhotoIndex={selectedPhoto}
      />
      <h2 className="section-headline">Videos</h2>
      {videos.length < 1 && canLoadVideo ? (
        <Spinner animation="border"></Spinner>
      ) : (
        ""
      )}
      {!canLoadVideo ? (
        <div className="video-container">
          <iframe
            title="videoPlayerforFailures"
            width="100%"
            src="https://www.youtube.com/embed/videoseries?list=PLiTEZfsrrC42SmqnyKRojZUI9F___D13N"
            frameBorder="0"
            allow="encrypted-media"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
        ""
      )}
      <Row className="card-deck">
        {videos.map((node) => {
          if (node != null) {
            if (node.id.kind === "youtube#video") {
              return (
                <Col key={node.id.videoId} lg={4} className="videoCard">
                  <VideoCard
                    link={`https://youtube.com/watch?v=${node.id.videoId}`}
                    title={node.snippet.title}
                    image={node.snippet.thumbnails.medium.url}
                    description={node.snippet.publishedAt.substring(0, 10)}
                    handleShowCallback={() => handleShow(node)}
                  />
                </Col>
              );
            }
          }
          return null;
        })}
      </Row>
      <Modal className="videoModal" show={showVideoModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedVideo.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="video-container">
            <iframe
              title="videoPlayer"
              width="100%"
              // height="600px"
              src={selectedVideo.link}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Media;

export const GalleryQuery = graphql`
  query GalleryQuery {
    allContentfulGallery {
      nodes {
        images {
          fixed(width: 1920) {
            width
            height
            ...GatsbyContentfulFixed_tracedSVG
          }
          description
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
